* {
    margin: 0;
    padding: 0;
    outline: none;
  }
  
  .container {
    width: 280px;
    height: 500px;
    margin: 30px auto;
  }
  
  .card {
    border-radius: 5px;
    border: 2px solid rgba(128, 128, 128, 0.409);
  }
  
  .card-head {
    position: relative;
    height: 100%;
    min-height: 10rem;
    max-height: 10rem;
    align-items: center;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    background: #a4a4a4;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #a4a4a4 8%, #515151 83%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #a4a4a4 8%, #515151 83%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,#a4a4a4 8%, #515151 83%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#a4a4a4', endColorstr='#515151', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    border-radius: 5px 5px 0 0;
  }

  .button-menu{
    position: absolute;
    z-index: 2;
    top: -8rem;
    right: 0rem;
  }
  
  .card-logo {
    width: 55px;
    margin: 20px;
  }
  
  .product-img {
    position: absolute;
    width: 100%;
    height: 100%;
    
  }
  
  .product-detail {
    padding: 0 20px;
    font-size: 11px;
    color: #fff;
  }
  
  .product-detail h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 2px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  
  .back-text {
    display: inline-block;
    font-size: 125px;
    font-weight: 900;
    margin-left: -7px;
    margin-top: -12px;
    opacity: 0.1;
  }
  
  .card-body {
    background: #e4e4e4;
    border-radius: 0 0 5px 5px;
    position: relative;
  }
  
  .product-title {
    padding: 18px 5px 0px 5px;
    display: block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
  }
  
  .product-title b {
    font-weight: 900;
    letter-spacing: 0px;
  }
  
  .badge {
    position: absolute;
    font-size: 10px;
    font-weight: 300;
    color: #fff;
    background: #4b2569c7;
    padding: 2px 5px;
    border-radius: 4px;
    top: -10px;
    left: 35%;
    margin-left: 5px;
  }
  
  .product-caption {
    display: block;
    padding: 0 60px 0px 5px;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .product-rating {
    padding: 0 20px;
    font-size: 11px;
  }
  
  .product-rating i.grey {
    color: #acacab;
  }
  .product-size{
    position: absolute;
    bottom: 10px;
  }
  .product-size h4 {
    font-size: 7px;
    padding: 0 5px 0px;
    text-transform: uppercase;
  }

  .product-prices-admin{
    position: absolute;
    font-size: 13px;
    top: 55px;
    right: 5px;
    zoom: .7;
  }

  .card-active{
    border-radius: 5px;
  }
  
  .ul-size {
    margin-left: 15px;
  }
  
  .ul-size li {
    list-style: none;
    float: left;
    margin-right: 20px;
  }
  
  .ul-size li a {
    display: inline-block;
    text-decoration: none;
    font-size: 11px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    text-align: center;
    line-height: 23px;
    color: #000;
  }
  
  .ul-size li a.active {
    background: #f35e3d;
    color: #fff;
  }
  
  .product-size:before,
  .product-size:after {
    content: '';
    display: block;
    clear: both;
  }
  
  .product-color h4 {
    font-size: 11px;
    padding: 0 21px;
    margin-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
  }
  
  .ul-color {
    margin-left: 27px;
  }
  
  .ul-color li {
    list-style: none;
    float: left;
    margin-right: 20px;
  }
  
  .ul-color li a {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
  
  .ul-color li a.orange {
    background: #f35e3d;
  }
  
  .ul-color li a.green {
    background: #11e95b;
  }
  
  .ul-color li a.yellow {
    background: #ffd414;
  }
  
  .ul-color li a.active:after {
    position: absolute;
    content: '';
    display: inline-block;
    border: 1px solid #f35e3d;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-left: -5px;
    margin-top: -5px;
  }
  
  .product-price {
    position: absolute;
    background: #4b2569c7;
    padding: 0px 5px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    font-weight: 200;
    color: #fff;
    border-radius: 5px;
    margin-top: -13px;
    margin-left: -5px;
    z-index: 1;
    bottom: -1px;
    right: -1px;
  }
  
  .product-price b {
    margin-left: 5px;
  }
  

  