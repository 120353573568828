.option-button-rounded{
    width: 30px;
    height: 30px;
}

.icon-option-button-rounded{
    margin: auto;
}

.card-img-foto-min{
    border: #9302f8 1.5px solid;
}
