@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

@font-face {font-family: "slowdex";src: url("assets/fonts/Slowdex.ttf");}
@font-face {font-family: "tenuity";src: url("assets/fonts/Tenuity.ttf");}
@font-face {font-family: "grasstrack";src: url("assets/fonts/Grasstrack.ttf");}
@font-face {font-family: "foxes";src: url("assets/fonts/Foxes.ttf");}
@font-face {font-family: "kenyan";src: url("assets/fonts/kenyan\ coffee\ rg.otf");}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font1 {font-family: 'Barlow Condensed', sans-serif;}
.font2 {font-family: 'Righteous', sans-serif;}
.font3 {font-family: 'slowdex', sans-serif;}
.font4 {font-family: 'tenuity', sans-serif;}
.font5 {font-family: 'grasstrack', sans-serif;}
.font6 {font-family: 'foxes', sans-serif;}
.font7 {font-family: 'kenyan', sans-serif;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-datepicker-wrapper{ width: 100%;}
.align-center{
  align-content: center;
}

.dialog-header-hide .p-dialog-header{ display: none;}
.dialog-header-hide .p-dialog-content{
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.p-dialog-content::-webkit-scrollbar {
  width: .4rem;
  background: #e9e9e9;
  border-radius: 4px;
  position: absolute;
  left: -10px;
}
.p-dialog-content::-webkit-scrollbar-thumb {
  width: .4rem;
  background: #b2b2b2;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.p-dialog-content::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.p-dialog-content::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items{
  padding-left: 0px;
}

.p-menu-list{
  padding-left: 0px;
  margin-bottom: 0px;
}

div.hr-style1::after{
  content: "";
  display: block;
  border: 0; 
  height: 2px !important; 
  background: linear-gradient(127deg, #f0f0f0, rgb(123, 123, 123), #f0f0f0) !important;
}
.p-toast-message-text{
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1.5rem;
  font-weight: 100;
  padding: .8rem;
}
.p-toast-message-content{
  color: rgb(51, 51, 51);
  align-items: center;
  padding: 0rem 1rem;
}
.p-inputnumber{width: 100%;}
.p-inputnumber input{
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.p-image-toolbar{
  color: aliceblue;
}

.checkbox-neutral .p-checkbox-box{
  border: 1px solid black;
}
.checkbox-neutral:not(.p-checkbox-checked) .p-checkbox-box {
  background-color: lightgray;
}


.p-input-style1 .p-inputnumber-input{
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: rgba(226, 226, 226, 0.433);
  border-bottom: .2rem solid rgb(177, 177, 177);
  padding-top: 0px;
  padding-bottom: 0px;
  
}


.stepper-style1 .p-steps .p-steps-item::before{ border-top: 3px solid #b6b6b6; }
.stepper-style1 .p-steps .p-steps-item::before{ border-top: 3px solid #b6b6b6; }
.stepper-style1 ol{ padding-left: 0px; }
.ch-border-2 .p-checkbox-box:not(.p-highlight){border: 3px solid #d4d4d4a9}
.ch-rounded .p-checkbox-box{border-radius: 5px;}


