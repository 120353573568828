
  /* Estilos para los elementos de la tarjeta */
  .card-item {
    max-width: calc(12vw - .4rem); /* Ancho máximo de cada tarjeta (ajusta según tus necesidades) */
  }

  .options-inventory-dropdown{
    z-index: 4;
  }

  /* Media query para dispositivos con un ancho de pantalla máximo de 768px (ajusta según tus necesidades) */
@media (max-width: 768px) {
    /* Cambia el ancho máximo de las tarjetas a 50% en dispositivos móviles */
    .card-item {
      max-width: calc(25% - .4rem); 
    }
  }

  @media (max-width: 650px) {
    /* Cambia el ancho máximo de las tarjetas a 50% en dispositivos móviles */
    .card-item {
      max-width: calc(33% - .4rem); 
    }
  }

  .col-span-12 {
    grid-column: span 12 / span 12;
  }
  .col-span-11 {
    grid-column: span 11 / span 11;
  }
  .col-span-10 {
    grid-column: span 10 / span 10;
  }
  .col-span-9 {
    grid-column: span 9 / span 9;
  }
  .image-gallery.image-gallery-using-mouse{
    align-items: center;
    justify-content: center;
    display: flex;
  }